import React from "react";
import { Link } from "@quintype/components";
import { NewspaperLogo } from "../footer/newspaper-logo";
import { FooterAppLogo } from "../footer/footer-app-logo";
import { AppStoreBadge } from "../../atoms/app-store-badge";
import { GooglePlayBadge } from "../../atoms/google-play-badge";
import FooterSocialMediaIcons from "../footer/footer-social-media-icon";
import "./main-footer.m.css";

const StaticFooterLinks = ({ staticFooterLinks }) => {
  return (
    <ul styleName="static-link">
      {staticFooterLinks.length >= 1 &&
        staticFooterLinks.map(item => {
          return item.isExternalLink && !(item.completeUrl.includes("ilkkapohjalainen.fi")) ? (
            <li styleName="static-link-list" key={item.title}>
              <a href={item.completeUrl} styleName="static-link-item" target="_blank" rel="noreferrer">
                {item.title}
              </a>
            </li>
          ) : (
            <li styleName="static-link-list" key={item.title}>
              <Link href={item.completeUrl || "/"} styleName="static-link-item">
                {item.title}
              </Link>
            </li>
          );
        })}
        {/*Evästeasetusplaceholder, toiminnallisuus gtm:stä*/}
        <li styleName="static-link-list" key="evästeasetukset">
          <a styleName="static-link-item evasteasetukset" className="evasteasetukset">
            Evästeasetukset
          </a>
        </li>
    </ul>
  );
};

const ContactInfo = () => {
  return (
    <React.Fragment>
      {
        <div styleName="footer-info">
          <div styleName="first-info">
            <p styleName="footer-contact-heading">Katuosoitteet</p>
            Koulukatu 10, Seinäjoki
            <br />
            Hietasaarenkatu 19, Vaasa
          </div>
          <div styleName="second-info">
            <p styleName="footer-contact-heading">Puhelin</p>
            06 2477 830 (vaihde)
          </div>
          <div styleName="third-info">
            <p styleName="footer-contact-heading"></p>
            <a styleName="link" target="_blank" href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/ota-yhteytta/#aukioloajat">Katso aukioloajat</a>
      </div>
          {/*<div styleName="second-info">
            <h4 styleName="footer-contact-heading">Avoinna</h4>
            Ti ja to klo 9-13
          </div>
          <div styleName="third-info">
            <p styleName="footer-contact-heading">Puhelin</p>
            06 2477 830 (vaihde)
      </div>*/}
          <div styleName="fourth-info">
            <div styleName="logo">
              <FooterAppLogo />
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
};

const FooterMenuLinks = ({ footerMenuLinks }) => {
  return (
    <React.Fragment>
      {footerMenuLinks.length >= 1 && (
        <div styleName="menu-link">
          {footerMenuLinks.map(item => {
            return (
              <ul key={item.title} styleName="footer-link-column">
                <li key={item.title} styleName="footer-item">
                  {item.title}
                  <br />
                </li>
                {item.children.map(child => (

                    <li key={child.title} styleName="footer-child-item">
                      {(child.isExternalLink && !((child.completeUrl.includes("ilkkapohjalainen.fi") ||
                            (child.completeUrl.includes("localhost")) ||
                            (child.completeUrl.includes("qtstage")))) || (child.completeUrl.includes("lounaspaikka")) === true) ? (
                        <a styleName="link" rel="noopener noreferrer" href={child.completeUrl} target="_blank">
                          {child.title}
                        </a>
                      ) : (
                        <a styleName="link" rel="noopener noreferrer" href={child.completeUrl}>
                          {child.title}
                        </a>
                      )}
                    </li>

                ))}
              </ul>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

const MainFooter = ({footerMenuLinks,staticFooterLinks}) => {
  //const [toHideNative] = useUrl();
  return (
    <div styleName="wrapper">
    {/*<div styleName={!toHideNative ? "wrapper" : "hide"}>*/}
      <div styleName="inner-wrapper">
        <div styleName="newspaper-logo">
          <NewspaperLogo />
        </div>
        <div styleName="footer-line"></div>
        <div styleName="footer-grid">
          <div styleName="top-row">
            <div styleName="left-column">
              <ContactInfo />
            </div>
            <div styleName="center-column">
              <div styleName="up-button-mobile-div">
                <button
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    })
                  }
                  styleName="up-button-mobile"
                >
                  <div styleName="up-button-text">
                    TAKAISIN YLÖS <i className="ri-arrow-up-line"></i>
                  </div>
                </button>
              </div>
              <br />
              <FooterMenuLinks footerMenuLinks={footerMenuLinks} />
            </div>
            <div styleName="right-column">
              <div>
                <button
                  onClick={() =>
                    window.scroll({
                      top: 0,
                      left: 0,
                      behavior: "smooth"
                    })
                  }
                  styleName="up-button"
                >
                  <div styleName="up-button-text">
                    TAKAISIN YLÖS <i className="ri-arrow-up-line"></i>
                  </div>
                </button>
              </div>
              <div styleName="footer-social-media">
                <FooterSocialMediaIcons />
              </div>
              <div styleName="app-badges">
                <GooglePlayBadge />
                <AppStoreBadge />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div styleName="footer-bottom-links">
        <StaticFooterLinks staticFooterLinks={staticFooterLinks} />
      </div>
    </div>
  );
};



export { MainFooter };
