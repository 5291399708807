import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import LazyEagerImage from "../../atoms/lazy-eager";
import "./app-logo.m.css";
import useUrl from "../../../hook/useUrl";

const AppLogo = () => {
  let currenturl=""
  let applogos= "";
  let homepageSlug="/";
  const [toHideNative,currentUrlSlug]=useUrl();
  useEffect(()=>{
    if (typeof window === "undefined") {
      console.log("Oops, window is not defined")
    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      currenturl=decodeURI.split('/')[3];
    }
  },[])
  const [isDisplayed, setIsDisplayed] = useState(false);
  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      setIsDisplayed(true);
    }, 0);
    return () => {
      isMounted = false;
    };
  }, [])

  if (isDisplayed === true) {
    switch (currentUrlSlug) {
      case "vaasalehti":
        applogos="/assetify/Vaasa_logo_rgb.png";
        homepageSlug = "/vaasalehti";
        break;
      case "epari":
        applogos="/assetify/epari_logo_rgb.png";
        homepageSlug = "/epari";
        break;
      case "":
        applogos= "/assetify/Ilkka-Pohjalainen_logo_h38.png";
        homepageSlug = "/";
        break;
      default:
        applogos= "/assetify/Ilkka-Pohjalainen_logo_h38.png";
        homepageSlug = "/";
    }
}
else {
  applogos= "/assetify/logo_placeholder.png";
  homepageSlug = "/";
}
  return(
  <Link href={homepageSlug}  aria-label="Etusivulle">
    <LazyEagerImage src={applogos}  alt="Logo" data-retina_logo_url="" className="publisher-logo-header"/>
  </Link>
)};

export { AppLogo };

