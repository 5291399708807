import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { object,string } from "prop-types";
import "./footer-social-media-icon.m.css";

const FooterSocialMediaIcons = ({ footerSocialLinks = [],currentUrlSlug }) => {
  let fbUrl=footerSocialLinks && footerSocialLinks["facebook-url"];
  let instaUrl=footerSocialLinks && footerSocialLinks["instagram-url"];
  let twitterUrl=footerSocialLinks && footerSocialLinks["twitter-url"];
  if(currentUrlSlug==="vaasalehti"){
    fbUrl="https://www.facebook.com/Vaasalehti"
    instaUrl="https://www.instagram.com/vaasa";
  }else if(currentUrlSlug==="epari"){
    fbUrl="https://www.facebook.com/kaupunkilehtiepari";
    instaUrl="https://www.instagram.com/eparilehti";
  }
  const list = [
    {
      componentName: "ri-facebook-fill",
      url: fbUrl,
      ariaLabel: "facebook"
    },
    {
      ...((currentUrlSlug !== "vaasalehti" && currentUrlSlug !== "epari") && {
        componentName: "ri-twitter-x-fill",
        url: twitterUrl,
        ariaLabel: "twitter"
      })
    },
    {
      componentName: "ri-instagram-fill",
      url: instaUrl,
      ariaLabel: "instagram"
    }
  ];

  return (
    <ul styleName="wrapper">
      {list.map((item, index) => {
        const FooterSocialMediaIcon = item.componentName;
        if (currentUrlSlug === "vaasalehti" || currentUrlSlug === "epari") {
          return (
            item.url && (
              <li styleName={`footer-share-item-${currentUrlSlug}`} key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.url || "/"}
                        aria-label={item.ariaLabel}
                      >
                  <i className={FooterSocialMediaIcon} />
                </a>
              </li>
            )
          );
        } else {
          return (
            item.url && (
              <li styleName="footer-share-item" key={index}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={item.url || "/"}
                        aria-label={item.ariaLabel}
                      >
                  <i className={FooterSocialMediaIcon} />
                </a>
              </li>
            )
          );
        }
      })}
    </ul>
  );
};

function mapStateToProps(state) {
  return {
    footerSocialLinks: get(state, ["qt", "config", "social-links"], [])
  };
}

FooterSocialMediaIcons.propTypes = {
  footerSocialLinks: object,
  currentUrlSlug:string
};

export default connect(mapStateToProps, () => ({}))(FooterSocialMediaIcons);
