import React from "react";
import get from "lodash/get";
import { array } from "prop-types";
import { connect } from "react-redux";
import useUrl from "../../../hook/useUrl";
import { MainFooter } from "../main-footer";
import { CommonFooter } from "./common-footer";
import "./footer.m.css";

const FooterBase = props => {
  const [,currentUrlSlug] = useUrl();
  return (
 <div>
      {currentUrlSlug==="vaasalehti" || currentUrlSlug==="epari"?
      (<CommonFooter
        staticFooterLinks={props.staticFooterLinks}
        footerMenuLinks={props.footerMenuLinks}
        currentUrlSlug={currentUrlSlug}
    />):
      (<MainFooter
        staticFooterLinks={props.staticFooterLinks}
        footerMenuLinks={props.footerMenuLinks}
    />)}
 </div>
  );
};
const mapStateToProps = state => {
  let currentUrl = "/";
  let menuDataToPull = "navigationMenu";
  if (typeof window !== "undefined") {
    const decodeURI = decodeURIComponent(window.location.href);
    currentUrl = decodeURI.split("/")[3];

    switch (currentUrl) {
      case "vaasalehti":
        menuDataToPull = "vaasaNavigationMenu";
        break;
      case "epari":
        menuDataToPull = "epariNavigationMenu";
        break;
      default:
        menuDataToPull = "navigationMenu";
    }
  }

  return {
    staticFooterLinks: get(state, ["qt", "data", menuDataToPull, "footerStaticMenuLinks"], []),
    footerMenuLinks: get(state, ["qt", "data", menuDataToPull, "footerMenuLinks"], [])
  };
};
FooterBase.propTypes = {
  staticFooterLinks: array,
  footerMenuLinks: array
};

const Footer = connect(mapStateToProps)(FooterBase);

export { Footer };
