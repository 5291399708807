import React, { useState, useEffect } from "react";
import { array, string } from "prop-types";
import { AppLogo } from "../app-logo";
import ErrorMessage from "../../atoms/error-message";
import WhitelistedLogin from "../navbar-whitelisted";
import useStorage from "../../../hook/useStorage";
import { Link } from "@quintype/components";
import { Search } from "../navbar-search";
import useUrl from "../../../hook/useUrl";
import Logout from "../navbar-logout";
import Login from "../navbar-login";
import "./top-header.m.css";

const TopHeaderInfo = ({ pageType }) => {
  const [, currentUrlSlug] = useUrl();
  const [checkUser] = useStorage();
  const [wListed, setWlisted] = useState(false);
  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    } else {
      const listed = JSON.parse(window.localStorage.getItem("ipUser"));
      setWlisted(listed)
    }
  })

  let digilehtiSlug = "";
  if (currentUrlSlug !== undefined && currentUrlSlug !== "epari" && currentUrlSlug !== "vaasalehti") {
    digilehtiSlug = `/nakoislehti`;
  } else {
    digilehtiSlug = `/${currentUrlSlug}/nakoislehti`;
  }
  return (
    <div styleName="logo-row">
      <div styleName="logo">
        <AppLogo />
      </div>
      <div styleName="desktop-error-message"><ErrorMessage /></div>
      <div styleName="top-header-login">
        {(checkUser && !wListed) && <Logout />}
        {(!checkUser && !wListed) && <Login />}
        {(wListed) && <WhitelistedLogin />}

        <div styleName="tablet-and-mobile-error-message"><ErrorMessage /></div>
      </div>
      <div styleName="top-header-digipaper">
        {((currentUrlSlug.includes("vaasalehti")) || (currentUrlSlug.includes("epari"))) ? (
          <Link styleName="nav-icon-link" href={digilehtiSlug}>
            <button aria-label="Näköislehti" styleName={`nav-btn-${currentUrlSlug}`}>
              <i styleName="nav-icon" className="ri-newspaper-line"></i>
            </button>
          </Link>
        ) : (
          <Link styleName="nav-icon-link" href={digilehtiSlug}>
            <button aria-label="Näköislehti" styleName="nav-btn">
              <i styleName="nav-icon" className="ri-newspaper-line"></i>
            </button>
          </Link>
        )}
      </div>
      <div styleName="top-header-search">
        {((currentUrlSlug.includes("vaasalehti")) || (currentUrlSlug.includes("epari"))) ? (
          <Link styleName="nav-icon-link" href="/search">
            <button aria-label="Haku" styleName={`nav-btn-${currentUrlSlug}`}>
              <i styleName="nav-icon" className="ri-search-line"></i>
            </button>
          </Link>
        ) : (
          <Search />
        )}
      </div>
    </div>
  );
};

const TopHeaderLinks = ({ topHeaderLinks }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [_, currentUrlSlug] = useUrl();
  const [selectOptions, setSelectOptions] = useState("");
  const linkItems = [
    { href: '/', text: 'Ilkka-Pohjalainen', class: 'select-ip' },
    { href: '/epari', text: 'Epari', class: 'select-epari' },
    { href: '/vaasalehti', text: 'Vaasa-lehti', class: 'select-vaasa' },
  ];

  useEffect(() => {
    setSelectOptions('Valitse lehti');
  }, [currentUrlSlug]);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getCustomerServiceLink = (item) => {
    const slug = currentUrlSlug === "epari" || currentUrlSlug === "vaasalehti" ? currentUrlSlug : "ilkka-pohjalainen";
    const matchingChild = item.children.find(child => child.title.toLowerCase() === slug);

    if (matchingChild) {
      return matchingChild.completeUrl;
    } else {
        return item.completeUrl;
      }
    };

  return (
    <div styleName="top-nav">
      <div styleName="top-nav-left">
        <div styleName="I-P-logo">
          <Link href="/" aria-label="Ilkka-Pohjalainen">
            <img src="/assetify/I-P_icon.png"
              alt="I-P Logo"
              data-retina_logo_url=""
              className="i-p logo" />
          </Link>
        </div>
        <div styleName="custom-dropdown">
          <div styleName="selected-link" className="selected-link" onClick={toggleDropdown}>
            {selectOptions && selectOptions}
            <ul styleName={`dropdown-list ${isOpen ? 'open' : 'hide'}`}>
              {linkItems.map((item) => (
                <li key={item.href} className={item.class}>
                  <a href={item.href}>{item.text}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ul styleName="top-nav-right">
        {topHeaderLinks.length >= 1 &&
          topHeaderLinks.map(item => {
            return item.isExternalLink && !(item.completeUrl.includes("ilkkapohjalainen.fi")) ?
              item.title === "Asiakaspalvelu" ?
              <li styleName="topnav-li" key={item.title}>
                  <a styleName="topnav-item" href={getCustomerServiceLink(item)} target="_blank">
                    {item.title}
                  </a>
                </li>
               : (
                <li styleName="topnav-li" key={item.title}>
                  <a styleName="topnav-item" href={item.completeUrl} target="_blank">
                    {item.title}
                  </a>
                </li>
              ) : (
                <li styleName="topnav-li" key={item.title}>
                  <Link styleName="topnav-item" href="/nakoislehti" >
                    {item.title}
                  </Link>
                </li>
              );
          })}
      </ul>
    </div>
  );
};

const TopHeaderBase = props => {
  return (
    <div>
      <TopHeaderLinks topHeaderLinks={props.topHeaderLinks} />
      <hr />
      <TopHeaderInfo pageType={props.pageType} />
    </div>

  );
};

TopHeaderLinks.propTypes = {
  topHeaderLinks: array
};

TopHeaderInfo.propTypes = {
  pageType: string
};

TopHeaderBase.propTypes = {
  pageType: string,
  topHeaderLinks: array
};

const TopHeader = TopHeaderBase;

export default TopHeader;
