import React from "react";
import { string } from "prop-types";

/* premium" articles == articles with "subscription" access level
   premium articles show stars all the time, whether the user is logged in or logged out.
   accessType 'login' or 'subscription' or 'premium' => show start all the time.
   accessType 'public' or null don't show stars for both loggged or logged out users.*/

const StarShowHide = ({ accessType }) => {
  return (
    (accessType === "subscription" || accessType === null) &&
    <i style={{/*display:"flex", justifyContent: "center", alignItems: "center"*/}} className="ri-star-fill" />

  )
};

StarShowHide.propTypes = {
  accessType: string
};

export default StarShowHide;
