import React from "react";
import { Link } from "@quintype/components";
import LazyEagerImage from "../lazy-eager";
import "./google-play-badge.m.css";

const GooglePlayBadge = () => (
  <Link href="https://play.google.com/store/apps/details?id=fi.ilkkapohjalainen.areader">
    <LazyEagerImage src="/assetify/google-play-badge.png"  alt="Lataa sovellus Google Play -kaupasta" className="google-play-badge"/>
  </Link>
);

export { GooglePlayBadge };

