import React from "react";
import PT from "prop-types";
import useUrl from "../../../hook/useUrl";
import { Link } from "@quintype/components";
import "./logout.m.css";
import { generateProfileUrl } from "../../utils/generate-profile-url";

const LogoutBase = (props) => {
  const [, currentUrlSlug] = useUrl();
  return (
    <React.Fragment>
      <ul styleName="logout-info">
        <Link href={generateProfileUrl("omat-tietoni", currentUrlSlug)}>
          <li styleName="menu-list-item-logout-info"><i className="ri-user-line"></i>Omat tietoni</li>
        </Link>
        <Link href={generateProfileUrl("tallentamani-artikkelit", currentUrlSlug)}>
          <li styleName="menu-list-item-logout-info"><i className="ri-bookmark-line"></i>Tallentamani artikkelit</li>
        </Link>
        <Link href={generateProfileUrl("seuraamani-aiheet", currentUrlSlug)}>
          <li styleName="menu-list-item-logout-info"><i className="ri-check-line"></i>Seuraamani aiheet</li>
        </Link>
        <Link href="/tulo/logout">
          <li styleName="menu-list-item-logout-info"><i className="ri-logout-box-line"></i>Kirjaudu ulos</li>
        </Link>

      </ul>
    </React.Fragment>
  );
};
export { LogoutBase };

LogoutBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  logoutStyle: PT.object,
  id: PT.string,
  toggleLogoutForm: PT.func,
  innerRef: PT.object
};
