import React, { memo } from "react";
import PT from "prop-types";
import "./whitelistedlogin.m.css";

const WhitelistedLoginBase = (props) => {

  return (
    <React.Fragment>
      <div id="loginBox" styleName="qt-login-form whitelisted-login" style={props.formStyle} className="qt-login-forms whitelisted-login">
        <div styleName="close-icon" onClick={props.toggle}>
          <span styleName="login-text">Kirjaudu sisään</span><i className="ri-close-line"></i>
        </div>
      </div>

      <button aria-label="Kirjaudu sisään" styleName="btn_login whitelisted-login" className="whitelisted-login" onClick={props.toggle} style={props.initialStyle}>
        <span styleName="login-text">Kirjaudu sisään </span><i styleName="login-icon" className="ri-user-shared-2-line"></i>
      </button>
    </React.Fragment>
  );

}
export default memo(WhitelistedLoginBase);

WhitelistedLoginBase.propTypes = {
  formStyle: PT.object,
  initialStyle: PT.object,
  loginStyle: PT.object,
  id: PT.string,
  toggle: PT.func,
  innerRef: PT.object
};
