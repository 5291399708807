import React from "react";
import LazyEagerImage from "../../../atoms/lazy-eager";
import { Link } from "@quintype/components";
import "./footer-app-logo.m.css";

const FooterAppLogo = () => (
  <Link href="https://ilkka.com/" target="_blank">
    <LazyEagerImage src="/assetify/ilkka_logo_white_h25.png" alt="Ilkka.com" className="ilkka-footer"/>
  </Link>
);

export { FooterAppLogo };
