import React, { useState, useEffect } from "react";
import assetify from "@quintype/framework/assetify";
import { Link } from "@quintype/components";
import Login from "../navbar-login";
import useUrl from "../../../hook/useUrl";
import useStorage from "../../../hook/useStorage";
import { generateUrl } from "../../utils/nav-urls";
import WhitelistedLogin from "../navbar-whitelisted";
import { getUserData } from "../../utils/getUserData";
import NavBarToggleBtn from "../../atoms/nav-bar-toggle-btn";
import ArrowDownIcon from "../../basic/images/arrow-down.svg";
import { generateProfileUrl } from "../../utils/generate-profile-url";
import "./menu-bar-local.m.css";

const MenuBarLocal = ({ mainNav, menuItems, hamburgerMenu, pageType, currentPath, slug }) => {
  const [firstParentId, setFirstParentId] = useState("");
  const [secondParentId, setSecondParentId] = useState("");
  const [goback, setGoback] = useState(true);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [isValikkoOpen, setIsValikkoOpen] = useState(false);
  const [index, setIndex] = useState("");
  const container = React.createRef();
  const [isTrue, setIsTrue] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [checkUser] = useStorage();
  const [wListed, setWlisted] = useState(false);
  const toggle = () => setIsTrue(!isTrue);
  const [, currentUrlSlug] = useUrl();

  let digilehtiSlug = "";
  if (currentUrlSlug !== undefined && currentUrlSlug !== "epari" && currentUrlSlug !== "vaasalehti") {
    digilehtiSlug = `/nakoislehti`;
  } else {
    digilehtiSlug = `/${currentUrlSlug}/nakoislehti`;
  }

  /*getting first letters from username to be shown for logged user*/
  let username = "";
  let usernameSplitted = "";
  let usernameFirstletters = "";
  const usrname = getUserData();
  if (typeof window !== 'undefined') {
    var usernam = usrname.username;
    if (usernam == null || usernam == undefined || username == null) {
      username = undefined;
    } else {
      username = usernam;
      usernameSplitted = username && username.split(/\s+/);
    }
    if (username == "undefined") {
      usernameFirstletters = ""
    } else {
      usernameFirstletters = usernameSplitted && usernameSplitted.map(function (name) {
        return name[0];
      }).join('');
    }
  }
  const showChild = (id, key) => {
    if (id === firstParentId) {
      setFirstParentId(id);
      setSecondParentId(null);
      setIsMegaMenuOpen(!isMegaMenuOpen);
      setIndex(key);
      setGoback(true);
      setIsActive(!isActive);
    }
    else {
      setFirstParentId(id);
      setSecondParentId(null);
      setIsMegaMenuOpen(true);
      setIndex(key);
      setGoback(true)
      setIsActive(true);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    if (typeof window === 'undefined') {
      return;
    } else {
      const listed = JSON.parse(window.localStorage.getItem("ipUser"));
      setWlisted(listed)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])
  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      setIsMegaMenuOpen(false);
      setIsActive(false);
      setIndex("");
    }
  };

  const showSecondChild = id => {
    setSecondParentId(id);
    setIsMegaMenuOpen(true);
    setGoback(!goback);
    setIsActive(true);
  };
  const onMenuToggle = (id) => setIndex(id);
  const showChildMenu = child => {
    return (
      (child.length >= 1 && isMegaMenuOpen === true) && (
        <div styleName="dropdown-list">
          <ul styleName="dropdown">
            {child.map(item => {
              return item.title && item.children.length < 1 ? (
                item.isExternalLink &&
                  !(item.completeUrl.includes("yhteissivusto.fiare.qtstage.io") ||
                    item.completeUrl.includes("imediat-web.fiare.qtstage.io") ||
                    item.completeUrl.includes("ilkkapohjalainen.fi")) ? (
                  <a
                    styleName={`menu-item-link-${slug}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.url || "/"}
                  >
                    <li key={item.title} styleName="no-children">
                      {item.title}
                      <hr styleName="parent-divider" />
                    </li>
                  </a>
                ) : (
                  <Link styleName={`menu-item-link-${slug}`} href={generateUrl(item, currentUrlSlug)}>
                    <li key={item.title} styleName="no-children">
                      {item.title}
                      <hr styleName="parent-divider" />
                    </li>
                  </Link>
                )

              ) : (
                <><a
                  styleName="menu-item-link-parent"
                  onClick={() => showSecondChild(item.id)}
                >
                  <li key={item.title} styleName="parent-link">
                    {item.title} {item.children.length >= 1 && <i className="ri-arrow-down-s-line"></i>}
                  </li>
                </a><hr styleName="parent-divider" />

                  {secondParentId === item.id ? showSecondChildMenu(item.children) : null}

                  <ul>
                    {item.children.map(item => {
                      return (
                        item.title && (
                          <li styleName={`second-child-menu-desktop ${item.title === "empty row" ? "empty-row" : ""}`} key={item.title}>
                            {item.isExternalLink &&
                              !(item.completeUrl.includes("yhteissivusto.fiare.qtstage.io") ||
                                item.completeUrl.includes("imediat-web.fiare.qtstage.io") ||
                                item.completeUrl.includes("ilkkapohjalainen.fi")) ? (
                              <a
                                styleName="menu-item-link second-child-menu"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.completeUrl || "/"}
                              >
                                {item.title}
                              </a>
                            ) : (
                              <Link styleName="menu-item-link second-child-menu" href={item.completeUrl || "/"}>
                                {item.title}
                              </Link>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </>

              );
            })}
          </ul>
        </div>
      )
    );
  };
  const showSecondChildMenu = child => {
    return secondParentId !== null && child.length >= 1 ? (
      <ul styleName={`${goback === true ? "hide-menu" : ""} `}>
        {child.map(item => {
          return (
            item.title && (
              <>
                {item.isExternalLink &&
                  !(item.completeUrl.includes("yhteissivusto.fiare.qtstage.io") ||
                    item.completeUrl.includes("imediat-web.fiare.qtstage.io") ||
                    item.completeUrl.includes("ilkkapohjalainen.fi")) ? (
                  <a
                    styleName="menu-item-link second-child-menu"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.completeUrl || "/"}
                  >
                    <li styleName={`second-child-menu-mobile ${item.title === "empty row" ? "empty-row" : ""}`} key={item.title}>
                      {item.title}
                    </li>
                  </a>
                ) : (
                  <Link styleName="menu-item-link second-child-menu" href={generateUrl(item, currentUrlSlug)}>
                    <li styleName={`second-child-menu-mobile ${item.title === "empty row" ? "empty-row" : ""}`} key={item.title}>
                      {item.title}
                    </li>
                  </Link>
                )}
              </>
            )
          );
        })}
        <hr styleName="parent-divider" /></ul>
    ) : null;
  };
  const currentSection = pageType === "section-page" ? currentPath.split("/")[1] : ""
  return (
    <div styleName={`wrapper-${slug}`}>
      <div styleName="inner-wrapper">
        <div styleName="menu-group">
          <nav styleName="nav">
            <React.Fragment>
              {mainNav.length >= 1 && (
                <>
                  <ul styleName="menu-list menu-list-left menu-list-left-desktop">
                    {mainNav.map(item => {
                      return item.children.length < 1 ? (
                        item.isExternalLink ? (
                          <a styleName={`menu-item-link-${slug}`} href={item.completeUrl || "/"}>
                            <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                              {item.title}
                            </li>
                          </a>
                        ) : (
                          <Link styleName={`menu-item-link-${slug}`} href={`/${currentUrlSlug}/${item.title.toLowerCase()}`}>
                            <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                              {item.title}
                            </li>
                          </Link>
                        )
                      ) : (
                        <a styleName={`menu-item-link-${slug}`} onClick={() => showChild(item.id, index)}>
                          <li
                            styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`}
                            key={item.id}
                          >
                            {item.title}
                            {item.children.length >= 1 && (
                              <i className={isActive && (firstParentId === item.id) ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                            )}

                            {(firstParentId === item.id) ? showChildMenu(item.children) : null}
                          </li>
                        </a>
                      );
                    })}
                  </ul>
                  <ul styleName="menu-list menu-list-left menu-list-left-tablet">
                    {mainNav.slice(0, 3).map(item => {
                      return item.children.length < 1 ? (
                        item.isExternalLink ? (
                          <a styleName={`menu-item-link-${slug}`} href={item.completeUrl || "/"}>
                            <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                              {item.title}
                            </li>
                          </a>

                        ) : (
                          <Link styleName={`menu-item-link-${slug}`} href={generateUrl(item, currentUrlSlug)}>
                            <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                              {item.title}
                            </li>
                          </Link>
                        )
                      ) : (
                        <a styleName={`menu-item-link-${slug}`} onClick={() => showChild(item.id, index)}>
                          <li
                            styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`}
                            key={item.id}
                          >
                            {item.title}
                            {item.children.length >= 1 && (
                              <i className={isActive && (firstParentId === item.id) ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                            )}
                            {(firstParentId === item.id) ? showChildMenu(item.children) : null}
                          </li>
                        </a>
                      );
                    })}
                  </ul>
                  <ul styleName="menu-list menu-list-left menu-list-left-mobile">
                    {mainNav.slice(0, 2).map(item => {
                      return item.children.length < 1 ? (
                        item.isExternalLink ? (
                          <a styleName={`menu-item-link-${slug}`} href={item.completeUrl || "/"}>
                            <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                              {item.title}
                            </li>
                          </a>
                        ) : (
                          <Link styleName={`menu-item-link-${slug}`} href={generateUrl(item, currentUrlSlug)}>
                            <li styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`} key={item.id} >
                              {item.title}
                            </li>
                          </Link>
                        )
                      ) : (
                        <a styleName={`menu-item-link-${slug}`} onClick={() => showChild(item.id, index)}>
                          <li
                            styleName={`menu-list-item ${currentSection === item["section-slug"] ? "active" : ""}`}
                            key={item.id}
                          >
                            {item.title}
                            {item.children.length >= 1 && (
                              <i className={isActive && (firstParentId === item.id) ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"}></i>
                            )}
                            {(firstParentId === item.id) ? showChildMenu(item.children) : null}
                          </li>
                        </a>
                      );
                    })}
                  </ul>
                </>
              )}
            </React.Fragment>
            <ul styleName="menu-list menu-list-right" ref={container}>
              <a styleName={`menu-item-link-${slug} menu-search-link`} href="/search"><li styleName={`menu-list-item-${slug} menu-search`}>Haku <i className="ri-search-line"></i></li></a>
              <a styleName={`menu-item-link-${slug} menu-digilehti-link`} href={digilehtiSlug}><li styleName={`menu-list-item-${slug} menu-digilehti`}>Näköislehti <i className="ri-newspaper-line"></i></li></a>
              <li styleName={`menu-login menu-login-${slug}`}>
                {(checkUser && !wListed) &&
                  <>
                    <span styleName={`user-link-${slug}`} onClick={() => toggle()}><i className="ri-user-line"></i><span styleName="omat-tiedot">{usernameFirstletters}</span><i className="ri-arrow-down-s-line"></i></span>
                    {isTrue && <ul styleName="login-info">
                      <Link href={generateProfileUrl("omat-tietoni", currentUrlSlug)}>
                        <li styleName="menu-list-item-login-info"><i className="ri-user-line"></i>Omat tietoni</li>
                      </Link>
                      <Link href={generateProfileUrl("tallentamani-artikkelit", currentUrlSlug)}>
                        <li styleName="menu-list-item-login-info"><i className="ri-bookmark-line"></i>Tallentamani artikkelit</li>
                      </Link>
                      <Link href={generateProfileUrl("seuraamani-aiheet", currentUrlSlug)}>
                        <li styleName="menu-list-item-login-info"><i className="ri-check-line"></i>Seuraamani aiheet</li>
                      </Link>
                      <Link href="/tulo/logout">
                        <li styleName="menu-list-item-login-info"><i className="ri-logout-box-line"></i>Kirjaudu ulos</li>
                      </Link>
                    </ul>}
                  </>}
                {(!checkUser && !wListed) && <Login />}
                {wListed && <WhitelistedLogin />}
              </li>
              {menuItems.map((menuItems, index) => {
                const getHomePage = (pageType === "Hamburger" && menuItems.title === "Talous") ? "active" : "";
                return (
                  menuItems.title && (
                    <React.Fragment>
                      {menuItems["title"] === "Hamburger" ? (
                        <li
                          styleName={`menu-list-item`}
                          key={menuItems.id}
                        >
                          <a styleName="hamburger-link" onClick={() => { showChild(menuItems.id, index); setIsValikkoOpen(!isValikkoOpen) }}>
                            <span styleName="valikkotext">Valikko</span>
                            <NavBarToggleBtn
                              onMenuToggle={() => onMenuToggle(index)}
                              isValikkoOpen={isValikkoOpen}
                              id={index}
                            />
                          </a>
                          {firstParentId === menuItems.id ? showChildMenu(hamburgerMenu) : null}
                        </li>
                      ) : menuItems.children.length < 1 ? (
                        <li styleName={`menu-list-item ${getHomePage} ${currentSection === menuItems["section-slug"] ? "active" : ""}`} key={menuItems.id} >
                          {menuItems.isExternalLink &&
                            !(item.completeUrl.includes("yhteissivusto.fiare.qtstage.io") ||
                              item.completeUrl.includes("imediat-web.fiare.qtstage.io") ||
                              item.completeUrl.includes("ilkkapohjalainen.fi")) ? (
                            <a
                              styleName="menu-item-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={menuItems.completeUrl || "/"}
                            >
                              {menuItems.title}
                            </a>
                          ) : (
                            <Link styleName="menu-item-link" href={menuItems.completeUrl || "/"}>
                              {menuItems.title}
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li
                          styleName={`menu-list-item ${getHomePage} ${currentSection === menuItems["section-slug"] ? "active" : ""}`}
                          key={menuItems.id}
                        >
                          <a styleName="menu-item-link" onClick={() => showChild(menuItems.id, index)}>
                            {menuItems.title}
                            {menuItems.children.length >= 1 && (
                              <img styleName="arrowDown" src={`${assetify(ArrowDownIcon)}`} />
                            )}
                          </a>
                          {(firstParentId === menuItems.id) ? showChildMenu(menuItems.children) : null}
                        </li>
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default MenuBarLocal;
