import React from "react";
import PT from "prop-types";

import "./nav-bar-toggle-btn.m.css";

export const NavBarToggleBtn = ({ onMenuToggle, isValikkoOpen, id }) => {
  return (
    <button  onClick={onMenuToggle} aria-label={`hamburger ${(isValikkoOpen && id === 0) ? "sulje valikko" : "avaa valikko"}`} styleName={`hamburger ${(isValikkoOpen && id === 0) ? "is-open" : ""}`}>
      <i className={`${(isValikkoOpen && id === 0) ? "ri-close-line" : "ri-menu-line"}`}></i>
    </button>
  );
};

NavBarToggleBtn.propTypes = {
  onMenuToggle: PT.func,
  isValikkoOpen: PT.bool
};

export default NavBarToggleBtn;
