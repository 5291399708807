export function getPaywallLimit(){
  let toInteger = 0;
  if (typeof window === 'undefined') {
    return;
  } else {
    // METERED_PAYWALL_ARTICLES_LIMIT is defined just here instead of setting and getting cookies
    // Change 'toIntegr' value to whatever is requird, currently defaulted to 0.
   /* const lmt = "METERED_PAYWALL_ARTICLES_LIMIT";
    const isLimit = document.cookie.match(new RegExp('(^| )' + lmt + '=([^;]+)'))||[];
    const isLimitStr = isLimit[0] || "";
    const sliceLimit = isLimitStr&&isLimitStr.split("=") || [];*/
     toInteger = 0; //sliceLimit[1]&&parseInt(sliceLimit[1]) || 0;
  }
  return toInteger;
}
