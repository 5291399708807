import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "@quintype/components";
import "./breaking-news.m.css";
import useUrl from "../hook/useUrl";
import useStorage from "../hook/useStorage";
import StarShowHide from "./atoms/star-show-hide";

const RenderBreakingNewsMarquee = ({ breakingNews }) => {

  const filterBreakingNews = breakingNews.filter(story => ((get(story, ["metadata", "linked-story-slug"])).split("/")[0]) === "ilkka-pohjalainen");
  const items = filterBreakingNews.slice(0, 3).map(story => {
    const linkedStorySlug = get(story, ["metadata", "linked-story-slug"]);
    const createSlug = linkedStorySlug.split("/").slice(1).join("/")
    const [accessType, setAccessValue] = useState();
    const [activeProducts] = useStorage();
    const storyId = (get(story, ["metadata", "linked-story", "id"]));
    Object.freeze(storyId);
    const fetchAccesses = async () => await fetch(`/api/v1/stories/${storyId}`).then(value => value.json()).then(json => setAccessValue(json.story.access)).catch(e=>console.log(e))
    useEffect(() => {
      fetchAccesses();
    }, [])
    const [isDisplayed, setIsDisplayed] = useState(false);
    useEffect(() => {
      setTimeout(() => {
        setIsDisplayed(true);
      }, 1000);
    }, [])
    var breakingNewsType = "default";
    if (story.metadata["story-group"] !== undefined) {
      breakingNewsType = story.metadata["story-group"];
    }
    if (linkedStorySlug) {
      if ((breakingNewsType === "breaking-news-tarjoukset") && (!activeProducts)) {
          return (
            isDisplayed ? <Link
              aria-label={`${story.headline}`}
              key={story.id}
              styleName={breakingNewsType}
              href={`/${createSlug}`}
            >
              <StarShowHide accessType={accessType} />
              {`${story.headline}`}
            </Link> : null
          );
      }
      if (breakingNewsType !== "breaking-news-tarjoukset") {
        return (
          <Link
            aria-label={`${story.headline}`}
            key={story.id}
            styleName={breakingNewsType}
            href={`/${createSlug}`}
          >
            <StarShowHide accessType={accessType} />
            {`${story.headline}`}
          </Link>
        );
      }
    }
    else {
      return (
        <div key={story.id} styleName="item">
            <StarShowHide accessType={accessType} />
            {story.headline}
        </div>
      );
    }
  });
  return (
    <div>
      {items}
    </div>

  );
};

export const BreakingNewsViewBase = ({ breakingNews = [], config, storytemplate }) => {
  const [toHideNative] = useUrl();
  const [toHide, setToHide] = useState(false);

  if (storytemplate === "wau-juttu") {
    return null;
  } else if ((!breakingNews.length || breakingNews.length === 0) && (storytemplate !== "wau-juttu")) {
    return <div styleName="empty-div-margin-bottom"></div>;
  }

  const pageType = config["page-type"];

  //Show breaking news on pages that fit to following condition
  const showBreakingNews = pageType === "home-page" || pageType === "epari-home-page" || pageType === "vaasa-home-page" ||
    pageType === "section-page" ||
    (pageType === "story-page" && storytemplate !== "wau-juttu")

  //If the condition above is false, hide breaking news
  if (!showBreakingNews) {
    setToHide(true);
  }

  return (
    <div styleName={(toHide || toHideNative) ? "hide" : "orangee-marquee-container"}>
      <div>
        <RenderBreakingNewsMarquee breakingNews={breakingNews} />
      </div>
    </div>
  );
};

BreakingNewsViewBase.propTypes = {
  breakingNews: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string,
      id: PropTypes.string,
      metadata: PropTypes.shape({
        "linked-story-slug": PropTypes.string
      })
    })
  ),
  config: PropTypes.object,
  storytemplate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

// get the type of story-template, if not on a story page, this won't give any value.
const mapStateToProps = state => ({
  storytemplate: get(state, ["qt", "data", "story", "story-template"], {})
});

export const BreakingNewsView = connect(mapStateToProps, () => ({}))(BreakingNewsViewBase);
