import { useEffect, useState } from "react";

const useUrl = () => {
  const [toHideNative, setToHideNative] = useState(false);
  const [currentUrlSlug, setCurrentUrlSlug] = useState("");
  useEffect(() => {
    if (typeof window === "undefined") {
       console.log("Oops, `window` is not defined")

     } else {
       const url = new URL(window.location.href);
       const urlSegments = url.pathname.split('/');
       const nativePageObj = urlSegments[1];
       setCurrentUrlSlug(nativePageObj);
       const nativePage = nativePageObj.includes("kaupallinen-yhteistyo");
       if (nativePage) {
        setToHideNative(true);
       }
     }

  }, [])

  return [toHideNative,currentUrlSlug]
}
export default useUrl;
