import { useEffect, useState } from "react";
import axios from "axios";
const useMessage = () => {
  const [errorMessage, setErrorMessage] = useState(null);

  const setNullMessage = () => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 15000);
  };
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    } else {
      const searchQueryObj = window.location.href;

      const errorIncludes = searchQueryObj.includes("errorMessage");
      const queryParams = new URLSearchParams(window.location.search);
      const noTuloSession = queryParams.get('infoMessage');
      if (errorIncludes) {
        setErrorMessage("Tarkista käyttäjätunnus ja salasana.");
      }else if (noTuloSession) {
        setErrorMessage(noTuloSession);
      }
    }
    setNullMessage();
  }, [])


  return [errorMessage]
}
export default useMessage;
