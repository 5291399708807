import React, { useEffect } from "react";
import { Link } from "@quintype/components";
import useUrl from "../../../../hook/useUrl";
import LazyEagerImage from "../../../atoms/lazy-eager";
import "./newspaper-logo.m.css";

const NewspaperLogo = () => {
  let currenturl = "";
  let applogos = "";
  let homepageSlug = "/";
  const [toHideNative, currentUrlSlug] = useUrl();
  useEffect(() => {
    if (typeof window === "undefined") {
      console.log("Oops, window is not defined");
    } else {
      const decodeURI = decodeURIComponent(window.location.href);
      currenturl = decodeURI.split("/")[3];
    }
  }, []);
  switch (currentUrlSlug) {
    case "vaasalehti":
      applogos = "/assetify/Vaasa_logo_nega_rgb.png";
      homepageSlug = "/vaasalehti";
      break;
    case "epari":
      applogos = "/assetify/epari_logo_valk_rgb.png";
      homepageSlug = "/epari";
      break;
    case "":
      applogos = "/assetify/ip-footer-logo-valk.png";
      homepageSlug = "/";
      break;
    default:
      applogos = "/assetify/ip-footer-logo-valk.png";
      homepageSlug = "/";
  }
  return (
    <Link href={homepageSlug} aria-label="Etusivulle">
      <LazyEagerImage src={applogos} data-retina_logo_url="" alt="Ilkka-Pohjalainen Logo" className="newspaper-logo-image" />
    </Link>
  );
};

export { NewspaperLogo };
