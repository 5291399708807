import React from "react";
import { Link } from "@quintype/components";
import "./app-store-badge.m.css";
import LazyEagerImage from "../lazy-eager";

const AppStoreBadge = () => (
  <Link href="https://apps.apple.com/fi/app/ilkka-pohjalainen/id1493932582?l=fi">
    <LazyEagerImage src="/assetify/app-store-badge.png" alt="Lataa sovellus App Storesta" styleName="app-store-badge"/>
  </Link>
);

export { AppStoreBadge };

