import React, { useEffect, useState } from "react";

import WhitelistedLoginBase from "./whitelistedlogin.js";
import "./whitelistedlogin.m.css";

const WhitelistedLogin = () => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setInitialized(true)
  }, [])

  const toggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const formStyle = {
    display: isMenuOpen ? "flex" : "none",
    //margin: this.state.isLoginFormOpen ? "-16px 0" : ""
  };
  const initialStyle = {
    display: isMenuOpen ? "none" : "inline-block"
  };
  const loginStyle = {
    display: isMenuOpen ? "block" : "none"
  };

  return (
    <React.Fragment>
      <div styleName="menubar-login">
      <WhitelistedLoginBase
          toggle={toggle}
          formStyle={formStyle}
          loginStyle={loginStyle}
          initialStyle={initialStyle}
          />
      </div>
      <div styleName="whitelisted-login-wrapper" style={loginStyle}>
        <div styleName="login-wrapper-inside">
          <div styleName="login-wrapper-text">
          Kirjautuminen, asiasanojen seuraaminen ja artikkeleiden tallentaminen poissa käytöstä. Artikkelit vapaasti luettavissa.
          </div>
          {/*<div styleName="login-wrapper-text">
            <a styleName="create-account" href={links}>Luo tili</a>
          </div>*/}
        </div>
      </div>
    </React.Fragment>
  );

}

export default WhitelistedLogin;
