import React from "react";
import useMessage from "../../../hook/useMessage";

import './error-message.m.css';

const ErrorMessage = () => {
  const[errorMessage]=useMessage();
  return(errorMessage&&<div styleName="errorMessage" className="errorMessage">{errorMessage}</div>)
};

export default ErrorMessage;
