export const generateUrl = (item, currentUrl) => {
  const convertScandinavianToEnglish = (inputString) => {
    const characterMap = {
      'ä': 'a',
      'ö': 'o',
      // Add more mappings as needed
    };
    const pattern = new RegExp(`[${Object.keys(characterMap).join('')}]`, 'g');
    const resultString = inputString.replace(pattern, (match) => characterMap[match] || match);
    return resultString;
  };

  const sectionSlugUrl = item["section-slug"] && `/${currentUrl}/${item["section-slug"]}`;
  const defaultUrl = `/${currentUrl}/${convertScandinavianToEnglish(item.title.toLowerCase())}`;

  return item.completeUrl !== "/#" ? item.completeUrl : sectionSlugUrl || defaultUrl;
};
