import React, { useEffect, useState } from "react";
import LoginBase from "./login.js";
import "./login.m.css";
import { createUrl } from "../../utils/createurl.js";

const Login = () => {

  const textInput = React.createRef();
  const textInputMob = React.createRef();
  const linksAccount=createUrl("createaccount");
  const [isLoginFormOpen, setIsLoginFormOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setInitialized(true)
  }, [])

  const toggleLoginForm = () => {
    setIsLoginFormOpen(!isLoginFormOpen);
    textInput.current.focus();
    textInputMob.current.focus();
  };

  const formStyle = {
    display: isLoginFormOpen ? "flex" : "none",
  };
  const initialStyle = {
    display: isLoginFormOpen ? "none" : "inline-block"
  };
  const loginStyle = {
    display: isLoginFormOpen ? "block" : "none"
  };

  return (
    <React.Fragment>
      <div styleName="menubar-login">
        <LoginBase
          toggleLoginForm={toggleLoginForm}
          formStyle={formStyle}
          loginStyle={loginStyle}
          initialStyle={initialStyle}
          innerRef={textInput}
          ref={null}
        />
      </div>
      <div styleName="login-wrapper" style={loginStyle}>
        <div styleName="login-wrapper-inside">
          <div styleName="login-wrapper-text">
            <h3>Kirjaudu sisään</h3>
          </div>
          <LoginBase
            toggleLoginForm={toggleLoginForm}
            initialStyle={initialStyle}
            innerRef={textInputMob}
            ref={null}
          />
          <div styleName="create-account">
            <a href={linksAccount}>Luo tili</a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

export default Login;
