import React, { useEffect, useState } from "react";

import { SearchBase } from "./search.js";
import "./search.m.css";

const Search = () => {

  const textInput = React.createRef();
  const textInputMob = React.createRef();

  const [isSearchFormOpen, setIsSearchFormOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(()=>{
    setInitialized(true)
  },[])


  const toggleSearchForm = () => {
    setIsSearchFormOpen(!isSearchFormOpen);
    textInput.current.focus();
    textInputMob.current.focus();
  };
  const formStyle = {
    display: isSearchFormOpen ? "flex" : "none",
    margin: isSearchFormOpen ? "0 0" : "0 0"
  };
  const initialStyle = {
    display: isSearchFormOpen ? "none" : "inline-block"
  };
  const searchStyle = {
    transform: isSearchFormOpen ? "translate(0, 0)" : ""
  };
    return (
      <React.Fragment>
        <div styleName="qt-search">
          <SearchBase
            toggleSearchForm={toggleSearchForm}
            formStyle={formStyle}
            searchStyle={searchStyle}
            initialStyle={initialStyle}
            //id="formDesk"
            innerRef={textInput}
            ref={null}
          />
        </div>
        <div styleName="search-wrapper" style={searchStyle}>

            <div styleName="search-wrapper-inside">
              <SearchBase
                toggleSearchForm={toggleSearchForm}
                initialStyle={initialStyle}
                innerRef={textInputMob}
                ref={null}
              />
            </div>

        </div>
      </React.Fragment>
    );

}

export { Search };
