import React from 'react';
import { useSelector } from "react-redux";
import get from 'lodash/get';
export function createUrl(accountTypes){
    const envTypes= useSelector(state => get(state, ["qt", "config","sketches-host"],""))
    const currentHost= useSelector(state => get(state, ["qt","currentHostUrl"],""))
    let hostname = "";
    const returnUrl = `?continue=${encodeURIComponent(currentHost)}&accountOrigin=IP`;
    const stg = "https://imediat-web.fiare.qtstage.io";
  switch (accountTypes) {
    case "myaccount":
      if (envTypes===stg) {
        hostname = `https://imediat.payway-portal.stage.adeprimo.se${returnUrl}`;
      } else {
        hostname = `https://imediat.portal.worldoftulo.com${returnUrl}`;
      }
      break;
    case "forgotpassword":
      if (envTypes===stg) {
        hostname = `https://imediat.payway-portal.stage.adeprimo.se/Login/RequestPasswordReset${returnUrl}`;
      } else {
        hostname = `https://imediat.portal.worldoftulo.com/Login/RequestPasswordReset${returnUrl}`;
      }
      break;
    case "createaccount":
      if (envTypes===stg) {
        hostname = `https://imediat.payway-portal.stage.adeprimo.se/loginregistration${returnUrl}`;
      } else {
        hostname = `https://imediat.portal.worldoftulo.com/loginregistration${returnUrl}`;
      }
      break;
    case "subscriptions":
      if (envTypes===stg) {
        hostname= `https://imediat.payway-portal.stage.adeprimo.se/account/subscriptions${returnUrl}`;
      } else {
        hostname=`https://imediat.portal.worldoftulo.com/account/subscriptions${returnUrl}`;
      }
      break;
    case "changeaddress":
      if (envTypes===stg) {
        hostname=`https://imediat-ssp.stage.adeprimo.se/Address${returnUrl}`;
      } else {
        hostname=`https://imediat-ssp.worldoftulo.com/Address${returnUrl}`;
      }
      break;
    case "changetemporaryaddress":
      if (envTypes===stg) {
        hostname=`https://imediat-ssp.stage.adeprimo.se/TemporaryAddress${returnUrl}`;
      } else {
        hostname=`https://imediat-ssp.worldoftulo.com/TemporaryAddress${returnUrl}`;
      }
      break;
    case "intermission":
      if (envTypes===stg) {
        hostname=`https://imediat-ssp.stage.adeprimo.se/Intermission${returnUrl}`;
      } else {
        hostname=`https://imediat-ssp.worldoftulo.com/Intermission${returnUrl}`;
      }
      break;
    case "invoice":
      if (envTypes===stg) {
        hostname=`https://imediat-ssp.stage.adeprimo.se/Invoice${returnUrl}`;
      } else {
        hostname=`https://imediat-ssp.worldoftulo.com/Invoice${returnUrl}`;
      }
      break;
    default:
      break;
  }
  return hostname;

};
