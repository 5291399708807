function processData(firstParam, secondParam) {
  let output = "";
  for (let j = 0; j < firstParam.length; j++) {
    output += String.fromCharCode(firstParam.charCodeAt(j) ^ secondParam);
  }
  return output;
}
export function getUserData() {
  if (typeof window === 'undefined') {
    return;
  } else {
    var secondParam = window.localStorage.getItem("_ki_");

    var getUname = window.localStorage.getItem("u_ser");
    var getEmail = window.localStorage.getItem("m_ail");
    var username = secondParam&&getUname&&processData(getUname, secondParam);
    var email = secondParam&&getEmail&&processData(getEmail, secondParam);
    return {
      username: username,
      email: email,
    };
  }

}

