import React, { useEffect, useState } from "react";
import { getUserData } from "../../utils/getUserData.js";

import { LogoutBase } from "./logout.js";
import "./logout.m.css";
import useUrl from "../../../hook/useUrl";

const Logout = () => {

  //const textInput = React.createRef();
  //const textInputMob = React.createRef();

  const [isLogoutFormOpen, setIsLogoutFormOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [,currentUrlSlug]=useUrl();

  /*getting first letters from username to be shown for logged user*/
  let username = "";
  let usernameSplitted = "";
  let usernameFirstletters = "";
  const usrname= getUserData();
  if (typeof window !== 'undefined') {
    var usernam = usrname.username;
    if (usernam == null || usernam == undefined || username == null) {
      username = undefined;
    } else {
      username = usernam;
      usernameSplitted = username && username.split(/\s+/);
    }
    if (username == "undefined") {
      usernameFirstletters = ""
    } else {
      usernameFirstletters = usernameSplitted && usernameSplitted.map(function (name) {
        return name[0];
      }).join('');
    }
  }

  useEffect(() => {
    setInitialized(true);
  }, []);

  const toggleLogoutForm = () => {
    setIsLogoutFormOpen(!isLogoutFormOpen);
    //textInput.current.focus();
    //textInputMob.current.focus();
  };

  const formStyle = {
    display: isLogoutFormOpen ? "flex" : "none"
    // margin: this.state.isLogoutFormOpen ? "-16px 0" : ""
  };
  const initialStyle = {
    display: isLogoutFormOpen ? "none" : "inline-block"
  };
  const logoutStyle = {
    display: isLogoutFormOpen ? "block" : "none"
  };
  return (
    <React.Fragment>
      {((currentUrlSlug.includes("vaasalehti")) || (currentUrlSlug.includes("epari"))) ? (
      <div styleName="menubar-logout">
        <div styleName="close-icon" onClick={toggleLogoutForm} style={logoutStyle}>
          <i className="ri-close-line"></i>
        </div>
        {!isLogoutFormOpen &&<button aria-label="Kirjaudu ulos" styleName={`btn_logout-${currentUrlSlug}`} onClick={toggleLogoutForm} style={initialStyle}>
            <i styleName="login-icon" className="ri-user-line"></i>
            <div styleName="username-letters">{usernameFirstletters}</div>
        </button>}
      </div>
      ) : (
      <div styleName="menubar-logout">
        <div styleName="close-icon" onClick={toggleLogoutForm} style={logoutStyle}>
          <i className="ri-close-line"></i>
        </div>
        {!isLogoutFormOpen &&<button aria-label="Kirjaudu ulos" styleName="btn_logout" onClick={toggleLogoutForm} style={initialStyle}>
            <i styleName="login-icon" className="ri-user-line"></i>
            <div styleName="username-letters">{usernameFirstletters}</div>
        </button>}
      </div>
      )}
      <div styleName="logout-wrapper" style={logoutStyle}>
        <div styleName="logout-wrapper-inside">
          <LogoutBase
            toggleLogoutForm={toggleLogoutForm}
            initialStyle={initialStyle}
            //innerRef={textInputMob}
            ref={null}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Logout;
