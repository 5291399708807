export function generateProfileUrl(page, currentUrlSlug) {
  let pageRelatedSlug = "";
  let path = "";
  switch (currentUrlSlug) {
    case "vaasalehti":
      pageRelatedSlug = "vaasalehti";
      path = `/${pageRelatedSlug}/${page}`;
      break;
    case "epari":
      pageRelatedSlug = "epari";
      path = `/${pageRelatedSlug}/${page}`;
      break;
    default:
      path = `/${page}`
  }

  return path;
}

