
import { useEffect, useState } from "react"
import { browserName} from "react-device-detect";
//import useWhiteListedIP from "./useWhiteListedIp";
import { getPaywallLimit } from "../components/utils/getPaywallArticleLmt";

const useStorage = () => {
  const [checkUser, setCheckUser] = useState();
  const [activeProducts, setActiveProducts] = useState();
  const[whiteListed, setWhiteListed]= useState();
  //const[listed]= useWhiteListedIP();
  const[storeUser, setStoreUser]=useState(false);
  const[allTagIds, setAllTagIds]=useState([]);
  const[visitCount, setVisitCount]= useState(0);
  const[articlesLimit, setArticlesLimit]= useState(0);
  const[isVisitedSlug, setIsVisitedSlug]= useState(false);
  const userStore =()=>{
    if (typeof window === 'undefined') {
      return;
    } else {
      if(browserName==="Firefox" || "Edge"){
        setTimeout(() => {
          const userValues = JSON.parse(window.localStorage.getItem("userValues"));
          setStoreUser(userValues);
          const activeProducts = JSON.parse(window.localStorage.getItem("activeProducts"));
          setActiveProducts(activeProducts);
        }, 720);
      }else{
        const userValues = JSON.parse(window.localStorage.getItem("userValues"));
        setStoreUser(userValues);
        const activeProducts = JSON.parse(window.localStorage.getItem("activeProducts"));
        setActiveProducts(activeProducts);
      }
    }
  }
  useEffect(() => {
    userStore();
    if (typeof window === 'undefined') {
      return;
    } else {
      const isVisitedSlug = JSON.parse(window.localStorage.getItem("isVisitedSlug"));
      setIsVisitedSlug(isVisitedSlug)
      const visitCount = JSON.parse(window.localStorage.getItem("visitCount"));
      setVisitCount(visitCount)
      const paywallArticleLimit = getPaywallLimit();
      setArticlesLimit(paywallArticleLimit)
        setAllTagIds({
          tagId_105046:localStorage.getItem("tagId_105046") || null,
          tagId_105047:localStorage.getItem("tagId_105047") || null,
          tagId_106320:localStorage.getItem("tagId_106320") || null,
          tagId_106321:localStorage.getItem("tagId_106321") || null,
          tagId_106322:localStorage.getItem("tagId_106322") || null,
          tagId_106323:localStorage.getItem("tagId_106323") || null,
          tagId_106324:localStorage.getItem("tagId_106324") || null,
          tagId_106325:localStorage.getItem("tagId_106325") || null,
        });

      setCheckUser(storeUser)
      const listed = JSON.parse(window.localStorage.getItem("ipUser"));
      if(listed){
        setCheckUser(true)
      }
      if(checkUser){
        setActiveProducts(activeProducts);
      }else{
        setActiveProducts(false);
      }
      //const getIp = JSON.parse(window.localStorage.getItem("ipUser"));
      setWhiteListed(listed);
      const origins = window.location.ancestorOrigins&&window.location.ancestorOrigins[0];
      if(origins==='https://i-mediat.fiare.quintype.com' || origins==='https://imediat.staging.fiare.quintype.com'){
        setWhiteListed(true)
      }
    }
  }, [storeUser,checkUser, activeProducts,whiteListed])
  return [checkUser,activeProducts,whiteListed,allTagIds,visitCount,articlesLimit,isVisitedSlug]
}
export default useStorage
