import { renderIsomorphicComponent, renderComponent, renderBreakingNews } from "@quintype/framework/client/start";
import LoadingIndicatorComponent from "../isomorphic/components/atoms/loading-indicator";
import { BreakingNewsView } from "../isomorphic/components/breaking-news-view";
import { Footer } from "../isomorphic/components/layouts/footer";
import { pickComponent } from "../isomorphic/pick-component";
import { Header } from "../isomorphic/components/header";
import get from "lodash/get";

export function preRenderApplication(store) {
  const breakingNewsbaseProps = {
    hydrate: !global.qtLoadedFromShell,
    updateInterval: 10000
  };
  const hydrate = { hydrate: !global.qtLoadedFromShell };
  renderComponent(Header, "header", store, hydrate);
  var storyTemplate;
  const pageType = get(store.getState(), ["qt", "pageType"])
  if (pageType == "story-page") {
    storyTemplate = get(store.getState(), ["qt", "data", "story", "story-template"])
  }
  renderComponent(Header, "header", store, hydrate);
  (pageType === "home-page" || pageType === "vaasa-home-page" || pageType === "epari-home-page" || pageType === "section-page" || ((pageType === "story-page" || pageType === "story-preview") && storyTemplate !== "wau-juttu")) && renderBreakingNews("breaking-news-container", store, BreakingNewsView, breakingNewsbaseProps);
  //renderComponent(LoadingIndicatorComponent, "loading-indicator", store);
  renderComponent(Footer, "footer", store, hydrate);
}

// This is a separate file as everything from here on is hot reloaded when the app changes
export function renderApplication(store) {
  renderIsomorphicComponent("container", store, pickComponent, {
    hydrate: !global.qtLoadedFromShell
  });
}
