import React, { useEffect, useState } from "react";
import ErrorMessage from "../atoms/error-message";
import MenuBarLocal from "./menu-bar-local";
import MenuBarIlkka from "./menu-bar-ilkka";
import { array, string } from "prop-types";
import useUrl from "../../hook/useUrl";
import { connect } from "react-redux";
import TopHeader from "./top-header";
import get from "lodash/get";
import "./header.m.css";

//scrolling function
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);
  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);
  return scrollDirection;
};

// Common wrapper for navigation. We could add OffcanvasMenu, Navbar etc components here.
const HeaderBase = (props) => {
  const [toHideNative, currentUrlSlug] = useUrl();
  const [isDisplayed, setIsDisplayed] = useState(false);
  //check scrolling function
  const scrollDirection = useScrollDirection();

  useEffect(() => {
    let isMounted = true;

    setTimeout(() => {
      setIsDisplayed(true);
    }, 0);
    return () => {
      isMounted = false;
    };
  }, [])

  return (
    <React.Fragment>
      <div styleName={`header-wrapper ${scrollDirection === "down" ? "collapse" : "expand"}`}>
        <TopHeader pageType={props.pageType} topHeaderLinks={props.topHeaderLinks} />
        <div styleName="error-small-mobile-fix">
          <ErrorMessage />
        </div>
        <div>
          {isDisplayed ? (currentUrlSlug === "vaasalehti" || currentUrlSlug === "epari") && (
            <MenuBarLocal
              mainNav={props.mainNav}
              menuItems={props.menu}
              hamburgerMenu={props.hamburgerMenu}
              default={props.default}
              pageType={props.pageType}
              currentPath={props.currentPath}
              slug={currentUrlSlug}
            />
          ) : (<div styleName="menubar-placeholder"></div>)}{
            isDisplayed ? !(currentUrlSlug === "vaasalehti" || currentUrlSlug === "epari") &&
            (
              <MenuBarIlkka
                mainNav={props.mainNav}
                menuItems={props.menu}
                hamburgerMenu={props.hamburgerMenu}
                default={props.default}
                pageType={props.pageType}
                currentPath={props.currentPath}
              />
            ) : (<div styleName="menubar-placeholder"></div>)}
        </div>
      </div>
      {toHideNative ?
        <div style={{ height: "1rem" }}></div> :
        <div></div>}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  let currentUrl = "/"
  let menuDataToPull = "navigationMenu"
  if (typeof window === "undefined") {
    console.log("Oops, `window` is not defined")

  } else {
    const decodeURI = decodeURIComponent(window.location.href);
    currentUrl = decodeURI.split('/')[3];
    if (currentUrl === "vaasalehti") {
      menuDataToPull = "vaasaNavigationMenu";
    } else if (currentUrl === "epari") {
      menuDataToPull = "epariNavigationMenu"
    } else {
      menuDataToPull = "navigationMenu"
    }
  }
  return {
    menu: get(state, ["qt", "data", menuDataToPull, "menuItems"], []),
    pageType: get(state, ["qt", "config", "page-type"], ""),
    default: get(state, ["qt", "data", menuDataToPull, "default"]),
    hamburgerMenu: get(state, ["qt", "data", menuDataToPull, "hamburgerMenu"]),
    topHeaderLinks: get(state, ["qt", "data", menuDataToPull, "topNavMenuLinks"], []),
    mainNav: get(state, ["qt", "data", menuDataToPull, "mainNav"], []),
    currentPath: get(state, ["qt", "currentPath"])
  };
}

HeaderBase.propTypes = {
  menu: array,
  mainNav: array,
  hamburgerMenu: array,
  default: array,
  pageType: string
};

export const Header = connect(mapStateToProps, () => ({}))(HeaderBase);
